import { useApi } from '~/composables/api/useApi'

export function useSites() {
  const { fetchWithAuth } = useApi()
  const config = useRuntimeConfig()
  const sitesAPI = config.public.sitesAPI || 'https://api-sites.transkribus.eu'
  // GET Requests

  async function fetchSite(providedOptions: {
    url: string
    status?: any
    userId?: number
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/${providedOptions.url}`,
      'GET'
    )
  }

  async function fetchSitesTags(providedOptions: { url: string }) {
    return await fetchWithAuth(
      `${sitesAPI}/search/tagDef/${providedOptions.url}`,
      'GET'
    )
  }

  async function fetchColNames(providedOptions: { url: string }) : Promise<Collection[]> {
    return await fetchWithAuth(
      `${sitesAPI}/search/colNames/${providedOptions.url}`,
      'GET'
    ) as Collection[]
  }


  async function fetchRsLiteCollections(providedOptions: {
    url: string
    status?: any
    userId?: number
    limit?: number
    offset?: number
    customUrl: string,
    title: string,
    colId: number,
    userEmail: string,
    field: string,
    order : string
  }) {
    const defaultOptions = {
      limit: 10,
      offset: 0,
    }
    let path = `${sitesAPI}/sites`
    if (providedOptions.url) {
      path = `${sitesAPI}/sites/${providedOptions.url}`
    }
    return await fetchWithAuth(path, 'GET', {
      providedOptions,
      defaultOptions,
    })
  }

  async function fetchRSDocument(providedOptions: {
    id: number
    collection: any
    url: string
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.url}/${providedOptions.id}`,
      'GET',
      {
        providedOptions: {
          collection: providedOptions.collection,
          url: providedOptions.url,
        },
      }
    )
  }

  async function urlTaken(providedOptions: { url: string }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/urlTaken/${providedOptions.url}`,
      'GET',
      {
        providedOptions: {
          url: providedOptions.url,
        },
      }
    )
  }
  interface GetSites {
    id?: number
    pageNum?: number
    collection?: any
    url?: string
  }

  async function fetchRSPageByNum(providedOptions: GetSites) {
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.url}/${providedOptions.id}/pages/${providedOptions.pageNum}`,
      'GET',
      {
        providedOptions: {
          collection: providedOptions.collection,
          url: providedOptions.url,
        },
      }
    )
  }

  async function fetchSitesPageMeta(providedOptions: GetSites) {
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.url}/${providedOptions.id}/pages/${providedOptions.pageNum}/metadata`,
      'GET',
      {
        providedOptions: {
          collection: providedOptions.collection,
          url: providedOptions.url,
        },
      }
    )
  }

  async function fetchSitesDocMeta(providedOptions: {
    id: string
    collection: any
    url: string
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.url}/${providedOptions.id}/metadata`,
      'GET',
      {
        providedOptions: {
          collection: providedOptions.collection,
          url: providedOptions.url,
        },
      }
    )
  }

  // POST Requests

  async function createSitesCollection(providedOptions: {
    title?: string
    description?: string
    status?: string
    colIds?: number
    image?: any
    url?: string
    aboutText?: string
    userId?: number
    config?: any
    locales?: any
    imprint?: string
  }) {
    const defaultOptions = {
      status: 'Draft',
    }
    return await fetchWithAuth(`${sitesAPI}/sites`, 'POST', {
      data: { ...providedOptions, ...defaultOptions },
    })
  }

  async function fetchHierarchy(providedOptions: {
    hierarchyName?: string
    collections?: any
    url?: string
    hierarchyLevels?: any
    hierarchyNameLevel?: any
    entireHierarchy?: any
    hierarchyPath?: any
    includeDocs?: boolean
  }) {
    return await fetchWithAuth(`${sitesAPI}/search/hierarchy`, 'POST', {
      data: providedOptions,
    })
  }

  async function exportSitesDocument(providedOptions: {
    id: number
    collection?: any
    url: string
    email?: any
  }) {
    const defaultOptions = {
      limit: 10,
      offset: 0,
    }
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.url}/${providedOptions.id}/export`,
      'POST',
      {
        data: {
          collection: providedOptions.collection,
          url: providedOptions.url,
          email: providedOptions.email,
        },
        defaultOptions,
      }
    )
  }

  async function fetchRSSearchResults(providedOptions: {
    term: string
    collections?: any
    url?: string
    facets?: any
    range?: any
    documents?: any
    titles?: any
    page?: any
    fuzziness?: any
    sortField?: any
    sortDirection?: string
    offset?: number
    limit?: number
    filterFacets?: any
  }) {
    return await fetchWithAuth(`${sitesAPI}/search`, 'POST', {
      data: {
        term: providedOptions.term,
        collections: providedOptions.collections,
        url: providedOptions.url,
        facets: providedOptions.facets,
        range: providedOptions.range,
        documents: providedOptions.documents,
        titles: providedOptions.titles,
        page: providedOptions.page,
        fuzziness: providedOptions.fuzziness,
        sortField: providedOptions.sortField,
        sortDirection: providedOptions.sortDirection || 'asc',
        offset: providedOptions.offset || 0,
        limit: providedOptions.limit || 10,
        filterFacets: providedOptions.filterFacets,
      },
    })
  }

  async function uploadReadSearchImage(providedOptions: {
    customUrl: string
    type: any
    data: any
  }) {
    const formData = new FormData()
    formData.append('file', providedOptions.data)
    return await fetchWithAuth(
      `${sitesAPI}/sites/${providedOptions.customUrl}/image/${providedOptions.type}`,
      'POST',
      {
        data: formData,
      }
    )
  }

  async function fetchMetaFacets(providedOptions: {
    term?: string
    facets?: string
    filterFacets?: string
    range?: string
    groupDocId?: string
    documents?: string
    titles?: string
    fuzziness?: string
    collections?: string
    url?: string
    offset?: number
    limit?: number
  }) {
    return await fetchWithAuth(`${sitesAPI}/search/facets`, 'POST', {
      data: { ...providedOptions },
    })
  }

  async function fetchTagResults(providedOptions: {
    value?: number
    type?: string
    facets?: any
    facetValues?: any
    collections?: any
    url?: number
    tagTypes?: any
    documents?: any
    offset?: number
    limit?: number
  }) {
    const defaultOptions = {
      offset: 0,
      limit: 10,
    }
    return await fetchWithAuth(`${sitesAPI}/search/tags`, 'POST', {
      data: { ...providedOptions, ...defaultOptions },
    })
  }

  async function fetchSitesPageCount(providedOptions: { collections: any }) {
    return await fetchWithAuth(`${sitesAPI}/search/countPages`, 'POST', {
      data: { collections: providedOptions.collections },
    })
  }

  async function fetchRSDocumentPages(providedOptions: {
    id?: number
    collections?: any
    url?: string
    offset?: number
    limit?: number
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/search/documents/${providedOptions.id}/pages`,
      'POST',
      {
        data: providedOptions,
      }
    )
  }

  async function createHiddenPage(providedOptions: {
    colId?: number
    docId?: number
    pageId?: number
    note?: string
    userId?: number
    releaseDate?: string
  }) {
    return await fetchWithAuth(`${sitesAPI}/hidden`, 'POST', {
      data: providedOptions,
    })
  }

  async function fetchHiddenPage(providedOptions: { pageId?: number }) {
    return await fetchWithAuth(
      `${sitesAPI}/hidden/${providedOptions.pageId}`,
      'GET'
    )
  }

  async function deleteHiddenPage(providedOptions: { pageId?: number }) {
    return await fetchWithAuth(
      `${sitesAPI}/hidden/${providedOptions.pageId}`,
      'DELETE'
    )
  }

  // PUT Requests

  async function updateRsLiteCollection(options: {
    customUrl: number
    sites: any
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/${options.customUrl}`,
      'PUT',
      {
        data: { sites: options.sites },
      }
    )
  }

  // async function removeMasterTranslationKeys(data: Site) {
  //   data.locales = await Promise.all(
  //     data.locales.map(async locale => {
  //       let masterLocale
  //       console.log("🚀 ~ removeMasterTranslationKeys ~ locale:", locale)

  //       try {
  //         // Try to read the master JSON for the current locale
  //         masterLocale = await import(`~/locales/sites/${locale.code}.json`)
  //       } catch (error) {
  //         // If the master JSON for the current locale doesn't exist, use en.json
  //         masterLocale = await  import(`~/locales/sites/en.json`)
  //       }

  //       const masterKeys = Object.keys(masterLocale.default)
  //       const localeKeys = Object.keys(locale.translations)

  //       localeKeys.forEach(key => {
  //         if (masterKeys.includes(key) && locale.translations[key] === masterLocale.default[key]) {
  //           delete locale.translations[key]
  //         }
  //       })

  //       return locale
  //     })
  //   )

  //   return data
  // }

  async function updateSiteStatus(options: { customUrl: number; status: any }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/${options.customUrl}/status`,
      'PUT',
      {
        data: { status: options.status },
      }
    )
  }

  // DELETE Requests

  async function deleteSitesCollection(options: { customUrl: string }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/${options.customUrl}`,
      'DELETE'
    )
  }

  async function deleteReadSearchImage(options: {
    customUrl: string
    fileName: string
  }) {
    return await fetchWithAuth(
      `${sitesAPI}/sites/${options.customUrl}/image/${options.fileName}`,
      'DELETE'
    )
  }

  // non REST

  function fetchReadSearchImage(options: {
    colId: number
    type: any
    userId: number
  }) {
    let link = `${sitesAPI}/sites/${options.colId}/${options.userId}/image/${options.type}`
    return link
  }

  return {
    createSitesCollection,
    deleteSitesCollection,
    fetchSite,
    urlTaken,
    updateRsLiteCollection,
    updateSiteStatus,
    fetchReadSearchImage,
    uploadReadSearchImage,
    deleteReadSearchImage,
    fetchMetaFacets,
    fetchTagResults,
    fetchRsLiteCollections,
    fetchRSDocument,
    fetchSitesPageCount,
    fetchRSDocumentPages,
    fetchRSPageByNum,
    fetchSitesPageMeta,
    exportSitesDocument,
    fetchRSSearchResults,
    fetchHierarchy,
    fetchSitesDocMeta,
    fetchSitesTags,
    createHiddenPage,
    fetchHiddenPage,
    deleteHiddenPage,
    fetchColNames
  }
}
